import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { hidePreLoader, showContentLoader, hideContentLoader } from "../../../loader/loaderSlice";
import facilityApiRequest from "../../../../app/api/facilities/facilityApiRequest";
import { store } from "../../../../app/store/configureStore";
import { setPlaceData } from "../search/searchSlice";
import { setAlertMessage } from "../../../common/settingsSlice";
import { request } from "http";

interface FacilitiesState {
    additionalInfo: any,
    placeAlerts: any[],
    waitListShow: boolean
}

const initialState: FacilitiesState = {
    additionalInfo: null,
    placeAlerts: [],
    waitListShow: false
}

export const getAdditionalPlaceInfo = createAsyncThunk<any, { placeId: any }>(
    'facilities/getAdditionalPlaceInfo',
    async ({ placeId }, thunkAPI) => {
        thunkAPI.dispatch(setAdditionalInfo(null));
        try {
            const additionalInfo = await facilityApiRequest.getAdditionalPlaceInfo(placeId);
            thunkAPI.dispatch(hidePreLoader());
            return additionalInfo;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPlaceAlerts = createAsyncThunk<any, { placeId: number }>(
    'facilities/getPlaceAlerts',
    async ({ placeId }, thunkAPI) => {
        thunkAPI.dispatch(setPlaceAlert([]));
        try {
            const placeAlerts = await facilityApiRequest.getPlaceAlerts(placeId);
            return placeAlerts;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)



const availabilityTabKeyHandler = (e: any, id: number) => {
    var TABKEY = 9;
    if (e.keyCode == TABKEY) {
        if (e.preventDefault) {
            e.preventDefault();
        }
        var element = document.getElementById("next-available-box-link-" + id);
        if (element) {
            element.focus();
        }
        return false;
    }
}

const processNextAvailableFacilityADA = (id: number) => {
    let element = document.getElementById("next-available-date-" + id) as HTMLElement;
    if (element) {
        element.focus();
        let prevAvailable = document.getElementById("prev-button-" + id) as HTMLElement;
        let nextAvailable = document.getElementById("next-button-" + id) as HTMLElement;
        let availableCheckbox = document.getElementById("weekendCheckBox-" + id) as HTMLElement;
        if ((prevAvailable && nextAvailable) || nextAvailable) {
            if (nextAvailable.addEventListener) {
                nextAvailable.addEventListener('keydown', ((e) => { availabilityTabKeyHandler(e, id) }), false);
            }
        } else if (prevAvailable) {
            if (prevAvailable.addEventListener) {
                prevAvailable.addEventListener('keydown', ((e) => { availabilityTabKeyHandler(e, id) }), false);
            }
        } else {
            if (availableCheckbox.addEventListener) {
                availableCheckbox.addEventListener('keydown', ((e) => { availabilityTabKeyHandler(e, id) }), false);
            }
        }
    }
}

export const getNextPrevAvailability = createAsyncThunk<any, { requestData: any }>(
    'facilities/getNextPrevAvailability',
    async ({ requestData }, thunkAPI) => {
        thunkAPI.dispatch(showContentLoader());
        try {
            const availability = await facilityApiRequest.getNextPrevAvailability(requestData);
            let availableDate = (availability && availability !== "0001-01-01T00:00:00") ? new Date(availability) : null
            let placeData: any = { ...store.getState().search.placeData };
            let selectedPlace: any = { ...placeData.SelectedPlace };
            let unAvailableFacilities = Object.assign([], selectedPlace.UnAvailableFacilities);
            let facilityIndex = unAvailableFacilities.findIndex((ele: any) => { return ele.FacilityId === requestData.facilityId });
            if (availableDate && availableDate.getTime() >= new Date(store.getState().common.futureBookingsDate.FutureBookingStartDate).getTime() && availableDate.getTime() <= new Date(store.getState().common.futureBookingsDate.FutureBookingEndDate).getTime()) {
                unAvailableFacilities[facilityIndex] = {
                    ...unAvailableFacilities[facilityIndex], weekend: requestData.weekend,
                    isNextPrevFacilityAvailable: true, isNextShow: true, isPrevShow: true, nextAvailableDate: availableDate
                }
            } else {
                unAvailableFacilities[facilityIndex] = {
                    ...unAvailableFacilities[facilityIndex],
                    weekend: requestData.weekend,
                    isNextPrevFacilityAvailable: false,
                    isNextShow: (unAvailableFacilities[facilityIndex].nextAvailableDate) ? (requestData.availabilityType === "nextavailable" ? false : true) : false,
                    isPrevShow: (unAvailableFacilities[facilityIndex].nextAvailableDate) ? (requestData.availabilityType === "nextavailable" ? true : false) : false,
                    nextAvailableDate: (unAvailableFacilities[facilityIndex].nextAvailableDate) ?
                        (requestData.availabilityType === "nextavailable" ?
                            new Date(new Date(unAvailableFacilities[facilityIndex].nextAvailableDate).getTime() + (1000 * 60 * 60 * 24)) :
                            new Date(new Date(unAvailableFacilities[facilityIndex].nextAvailableDate).getTime() - (1000 * 60 * 60 * 24))) : null
                }
            }
            selectedPlace.UnAvailableFacilities = Object.assign([], unAvailableFacilities);
            thunkAPI.dispatch(setPlaceData({ ...placeData, SelectedPlace: selectedPlace }));
            thunkAPI.dispatch(hideContentLoader());
            setTimeout(() => {
                processNextAvailableFacilityADA(requestData.facilityId);
            }, 500);
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const saveWaitList = createAsyncThunk<any, { requestData: any }>(
    'save/waitlistinfo',
    async ({ requestData }, thunkAPI) => {
        try {
            let waitListInfo: any = {
                "waitlistinfo": {
                    "waitlistId": 0,
                    "customerId": 0,
                    "arrivalDate": requestData.arrivalDate,
                    "endDate": requestData.endDate,
                    "placeId": requestData.placeId,
                    "facilityId": requestData.facilityId,
                    "unittypeId": requestData.unittypeId,
                    "itemStatus": 0,
                    "iDate": new Date(),
                    "facilityName": "",
                    "unitTypeName": "",
                    "placeName": "",
                    "data": 0,
                    "preferredContactType": requestData.preferredContactType,
                    "isADARequired": requestData.isADARequired
                }
            };
            const isSaved = await facilityApiRequest.saveWaiteList(waitListInfo);
            if (isSaved == 0) {
                thunkAPI.dispatch(setAlertMessage({ header: "Success", message: "Waitlist added successfully." }));
            }
            else if (isSaved == 2) {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: "Notification already added." }));
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const facilitiesSlice = createSlice({
    name: 'facilities',
    initialState,
    reducers: {
        setAdditionalInfo: (state, action) => {
            state.additionalInfo = action.payload;
        },
        setPlaceAlert: (state, action) => {
            state.placeAlerts = action.payload;
        },
        setWaitListShow: (state, action) => {
            state.waitListShow = action.payload;
        },
    },
    extraReducers: (builder => {
        builder.addCase(getAdditionalPlaceInfo.rejected, (state) => {
            state.additionalInfo = null;
        });
        builder.addCase(getPlaceAlerts.rejected, (state) => {
            state.placeAlerts = [];
        });
        builder.addMatcher(isAnyOf(getAdditionalPlaceInfo.fulfilled), (state, action) => {
            state.additionalInfo = action.payload;
        });
        builder.addMatcher(isAnyOf(getPlaceAlerts.fulfilled), (state, action) => {
            state.placeAlerts = action.payload;
        });
    })
})

export const { setAdditionalInfo, setPlaceAlert, setWaitListShow } = facilitiesSlice.actions;