import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import merchandiseApiRequest from "../../app/api/merchandise/merchandiseApiRequest";
import navigationService from "../../app/history/history";
import { onCloseModal } from "../../app/util/ada";
import { hideContentLoader, hidePreLoader, showContentLoader, showPreLoader } from "../../modules/loader/loaderSlice";
import { setShoppingCartTimer } from "../common/timerSlice";
import { onItemAddInCart } from "../customers/shoppingCartSlice";

interface MerchandiseState {
    onlineStoreDepartments: any,
    onlineStoreCategories: any,
    merchandiseItems: any,
    merchandiseItemById: any,
    departmentId: number,
    categoryId: number,
    searchText: string
}

const initialState: MerchandiseState = {
    onlineStoreDepartments: [],
    onlineStoreCategories: [],
    merchandiseItems: [],
    merchandiseItemById: [],
    departmentId: 0,
    categoryId: 0,
    searchText: ""
}

export const getOnlineStoreDepartments = createAsyncThunk<any>(
    'Merchandise/MerchandiseOnlineStoreDepartments',
    async (_, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const merchandiseOnlineStoreDepartment = await merchandiseApiRequest.getOnlineStoreDepartments();
            thunkAPI.dispatch(hideContentLoader());
            return merchandiseOnlineStoreDepartment.$values;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getOnlineStoreCategories = createAsyncThunk<any, {departmentId: number }>(
    'Merchandise/MerchandiseOnlineStoreCategories',
    async ({ departmentId }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const merchandiseOnlineStoreCategories = await merchandiseApiRequest.getOnlineStoreCategories(departmentId);
            thunkAPI.dispatch(hideContentLoader());
            return merchandiseOnlineStoreCategories.$values;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getMerchandiseItems = createAsyncThunk<any, { searchMerchandise: any }>(
    'Merchandise/GetMerchandiseItems',
    async ({ searchMerchandise }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const getMerchandiseItemsResponse = await merchandiseApiRequest.getMerchandiseItems(searchMerchandise);
            thunkAPI.dispatch(hidePreLoader());
            return getMerchandiseItemsResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getMerchandiseItemById = createAsyncThunk<any, { itemLookupCode: string }>(
    'Merchandise/GetMerchandiseItemById',
    async ({itemLookupCode }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const merchandiseItemByIdResponse = await merchandiseApiRequest.getMerchandiseItemById(itemLookupCode);
            thunkAPI.dispatch(hidePreLoader());
            return merchandiseItemByIdResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const purchaseMerchandise = createAsyncThunk<any, { purchaseMerchandiseRequest: any }>(
    'Merchandise/PurchaseMerchandise',
    async ({ purchaseMerchandiseRequest }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const purchaseMerchandiseResponse = await merchandiseApiRequest.purchaseMerchandise(purchaseMerchandiseRequest);
            const isSuccess = onItemAddInCart(thunkAPI.dispatch, purchaseMerchandiseResponse, null);
            if (isSuccess) {
                onCloseModal("merchandiseDetailModal", true);
                navigationService.navigation("/Customers/ShoppingCart");              
            } else {
                thunkAPI.dispatch(hidePreLoader());
            }           
            return purchaseMerchandiseResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const merchandiseSlice = createSlice({
    name: 'merchandise',
    initialState,
    reducers: {
        setDepartmentId: (state, action) => {
            state.departmentId = action.payload;
        },
        setCategoryId: (state, action) => {
            state.categoryId = action.payload;
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(getOnlineStoreDepartments.rejected, (state) => {
            state.onlineStoreDepartments = [];
        });
        builder.addCase(getOnlineStoreCategories.rejected, (state) => {
            state.onlineStoreCategories = [];
        });
        builder.addCase(getMerchandiseItems.rejected, (state) => {
            state.merchandiseItems = [];
        });
        builder.addCase(getMerchandiseItemById.rejected, (state) => {
            state.merchandiseItemById = [];
        });
        builder.addMatcher(isAnyOf(getOnlineStoreDepartments.fulfilled), (state, action) => {
            state.onlineStoreDepartments = action.payload;
        });
        builder.addMatcher(isAnyOf(getOnlineStoreCategories.fulfilled), (state, action) => {
            state.onlineStoreCategories = action.payload;
        });
        builder.addMatcher(isAnyOf(getMerchandiseItems.fulfilled), (state, action) => {
            state.merchandiseItems = action.payload;
        });
        builder.addMatcher(isAnyOf(getMerchandiseItemById.fulfilled), (state, action) => {
            state.merchandiseItemById = action.payload;
        });
    })
})

export const { setDepartmentId, setCategoryId, setSearchText } = merchandiseSlice.actions;