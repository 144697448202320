import { createAsyncThunk, createSlice, isAllOf, isAnyOf } from "@reduxjs/toolkit";
import activityApiRequest from "../../app/api/activities/activityApiRequests";
import { SessionSearchParameters } from "../../app/models/activity/searchSessionRequest";
import { hideContentLoader, hidePreLoader, showContentLoader, showPreLoader } from "../loader/loaderSlice";
import { store } from "../../app/store/configureStore";
import { setAlertMessage } from "../common/settingsSlice";
import { setShoppingCartTimer } from "../common/timerSlice";
import navigationService from "../../app/history/history";

interface ActivityDetailState {
    programTourDetails: any,
    searchSessionData: any,
    activityDetails: any,
    allowSessionSignupWindow: string,
    instructors: any,
    signUpPrecartDetails: any,
    processActivitySignUp: any,
    programSignUpDetails: any,
    programMeetingData: any,
    sendTellAFriendMessage: any,
    signUpModifyDetails: any,
    processSignUpModification: any
}

const initialState: ActivityDetailState = {
    programTourDetails: [],
    searchSessionData: [],
    activityDetails: [],
    allowSessionSignupWindow: "",
    instructors: [],
    signUpPrecartDetails: [],
    processActivitySignUp: [],
    programSignUpDetails: [],
    programMeetingData: [],
    sendTellAFriendMessage: null,
    signUpModifyDetails: [],
    processSignUpModification:[]
}

export const getProgramAndTourDetails = createAsyncThunk<any, { requestData: any }>(
    'activities/getProgramAndTourDetails',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const programTourDetails = await activityApiRequest.getProgramAndTourDetails(requestData);
            thunkAPI.dispatch(hidePreLoader());
            return programTourDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const searchSessionData = createAsyncThunk<SessionSearchParameters, { sessionParameters: SessionSearchParameters, searchPage: number }>(
    'activities/searchSessionData',
    async ({ sessionParameters, searchPage }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            thunkAPI.dispatch(setSearchSessionData(null));
            let dynamicMessage = store.getState().common.dynamicConfigMsg;
            const searchSessionData = await activityApiRequest.searchSessionData(sessionParameters, searchPage);
            if (searchSessionData.Sessions.length === 0) {
                thunkAPI.dispatch(setAlertMessage({ header: dynamicMessage["Globalresource_enumBrowsingCatalog"], message: dynamicMessage["Search_error_activity_found"] }));
            }
            thunkAPI.dispatch(hideContentLoader());
            return searchSessionData;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getActivityDetails = createAsyncThunk<any, { sessionParameters: any }>(
    'activities/getActivityDetails',
    async ({ sessionParameters }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const activityDetails = await activityApiRequest.getActivityDetails(sessionParameters);
            thunkAPI.dispatch(hidePreLoader());
            return activityDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const sessionSignupWindow = createAsyncThunk<any, { sessionSignupWindowParameter: any }>(
    'activities/allowSessionSignupWindow',
    async ({ sessionSignupWindowParameter }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const allowSessionSignupWindow = await activityApiRequest.allowSessionSignupWindow(sessionSignupWindowParameter);
            thunkAPI.dispatch(hideContentLoader());
            return allowSessionSignupWindow;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getInstructors = createAsyncThunk<any, { customerName: string }>(
    'activities/searchSessionData',
    async ({ customerName }, thunkAPI) => {
        try {
            let instructors = await activityApiRequest.getInstructor(customerName);
            return instructors;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const getSignUpPrecartDetails = createAsyncThunk<any, { signUpPreCartParameters: any }>(
    'activities/getSignUpPrecartDetails',
    async ({ signUpPreCartParameters }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const signUpPreCartDetails = await activityApiRequest.getSignUpPrecartDetails(signUpPreCartParameters);            
            thunkAPI.dispatch(hidePreLoader());
            return signUpPreCartDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const validateActivitySignUp = createAsyncThunk<any, { activitySignUp: any }>(
    'activities/validateActivitySignUp',
    async ({ activitySignUp }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const activitySignUpDetails = await activityApiRequest.validateActivitySignUp(activitySignUp);
            if (activitySignUpDetails.IsSuccess) {
                localStorage.removeItem("ActivitySignUp");
                localStorage.setItem('cart', JSON.stringify(activitySignUpDetails));
                localStorage.setItem('shoppingCartKey', activitySignUpDetails.ShoppingCartKey);
                navigationService.navigation("/Customers/ShoppingCart");
                thunkAPI.dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: false, shoppingCartTimerStartTime: null, shoppingCartTimerEndTime: null }));
                setTimeout(()=>{
                    thunkAPI.dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: true, shoppingCartTimerStartTime: activitySignUpDetails.StartTime, shoppingCartTimerEndTime: activitySignUpDetails.EndTime }));
                },0);               
            } else {
                thunkAPI.dispatch(hidePreLoader());              
            }
            return activitySignUpDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const getProgramSignUpDetails = createAsyncThunk<any, { programSignUpRequest: any }>(
    'activities/getProgramSignUpDetails',
    async ({ programSignUpRequest }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const programSignUpDetails = await activityApiRequest.getProgramSignUpDetails(programSignUpRequest);            
            thunkAPI.dispatch(hideContentLoader());
            return programSignUpDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getSelectedMeetingTimesForProgram = createAsyncThunk<any, { programSignUpRequest: any }>(
    'activities/GetSelectedMeetingTimesForProgram',
    async ({ programSignUpRequest }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showContentLoader());
            const meetingTimesForProgram = await activityApiRequest.getSelectedMeetingTimesForProgram(programSignUpRequest);            
            thunkAPI.dispatch(hideContentLoader());
            return meetingTimesForProgram;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const sendTellAFriendMessage = createAsyncThunk<any, { requestData: any }>(
    'activities/sendTellAFriendMessage',
    async ({ requestData }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const sendMessageResponse = await activityApiRequest.sendTellAFriendMessage(requestData);
            thunkAPI.dispatch(hidePreLoader());
            return sendMessageResponse;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const processActivitySignUpModification = createAsyncThunk<any, { signUpModification: any }>(
    'activities/processActivitySignUpModification',
    async ({ signUpModification }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const activitySignUpDetails = await activityApiRequest.processSignUpModification(signUpModification);
            if (activitySignUpDetails.IsSuccess) {
                localStorage.removeItem("ActivitySignUp");
                localStorage.setItem('cart', JSON.stringify(activitySignUpDetails));
                localStorage.setItem('shoppingCartKey', activitySignUpDetails.ShoppingCartKey);
                navigationService.navigation("/Customers/ShoppingCart");
                thunkAPI.dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: false, shoppingCartTimerStartTime: null, shoppingCartTimerEndTime: null }));
                setTimeout(()=>{
                    thunkAPI.dispatch(setShoppingCartTimer({ isShoppingCartTimerDisplay: true, shoppingCartTimerStartTime: activitySignUpDetails.StartTime, shoppingCartTimerEndTime: activitySignUpDetails.EndTime }));
                },0);               
            } else {
                thunkAPI.dispatch(hidePreLoader());              
            }
            return activitySignUpDetails;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const getSessionQuestionAnswers = createAsyncThunk<any, { sessionId: any, confirmationNumber: any }>(
    'activities/getSessionQuestionAnswers',
    async ({ sessionId, confirmationNumber }, thunkAPI) => {
        try {
            let questionAnswers = await activityApiRequest.getSessionQuestionAnswers(sessionId, confirmationNumber);
            return questionAnswers;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const processActivityUpdateInfo = createAsyncThunk<any, { sessionQuestionAnswers: any }>(
    'activities/processActivityUpdateInfo',
    async ({ sessionQuestionAnswers }, thunkAPI) => {
        try {
            thunkAPI.dispatch(showPreLoader());
            const response = await activityApiRequest.processActivityUpdateInfo(sessionQuestionAnswers);
            if (response.IsSuccess) {
                localStorage.removeItem("sessionId");
                localStorage.removeItem("confirmationNumber");
                thunkAPI.dispatch(hidePreLoader());
                thunkAPI.dispatch(setAlertMessage({ className: "", header: "Success", message: "Your information has been updated successfully." }));
                navigationService.navigation("/Customers/SignUps");
            } else {
                thunkAPI.dispatch(hidePreLoader());              
            }
            return response;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const activitySlice = createSlice({
    name: 'activity',
    initialState,
    reducers: {
        setSearchSessionData: (state, action) => {
            state.searchSessionData = action.payload
        },
        setActivityDetail: (state, action) => {
            state.activityDetails = action.payload
        },
        allowSessionSignupWindow: (state, action) => {
            state.allowSessionSignupWindow = action.payload
        },
        setsSignUpPrecartDetails: (state, action) => {
            state.signUpPrecartDetails = action.payload
        },
        processActivitySignUp: (state, action) => {
            state.processActivitySignUp = action.payload
        },
        programSignUpDetails: (state, action) => {
            state.programSignUpDetails = action.payload
        },
        programMeetingData: (state, action) => {
            state.programMeetingData = action.payload
        }, 
        setSignUpModifyDetails: (state, action) => {
            state.signUpModifyDetails = action.payload
        },     
        processSignUpModification: (state, action) => {
            state.processSignUpModification = action.payload
        }
    },
    extraReducers: (builder => {
        builder.addCase(getProgramAndTourDetails.rejected, (state) => {
            state.programTourDetails = [];
        });
        builder.addCase(getActivityDetails.rejected, (state) => {
            state.activityDetails = [];
        });
        builder.addCase(sessionSignupWindow.rejected, (state) => {
            state.allowSessionSignupWindow = "";
        });
        builder.addCase(getInstructors.rejected, (state) => {
            state.instructors = [];
        });
        builder.addCase(getSignUpPrecartDetails.rejected, (state) => {
            state.signUpPrecartDetails = [];
        });
        builder.addCase(validateActivitySignUp.rejected, (state) => {
            state.processActivitySignUp = [];
        });
        builder.addCase(getProgramSignUpDetails.rejected, (state) => {
            state.programSignUpDetails = [];
        });
        builder.addCase(getSelectedMeetingTimesForProgram.rejected, (state) => {
            state.programMeetingData = [];
        });
        builder.addCase(sendTellAFriendMessage.rejected, (state) => {
            state.sendTellAFriendMessage = [];
        });
        builder.addCase(processActivitySignUpModification.rejected, (state) => {
            state.processSignUpModification = [];
        });
        builder.addMatcher(isAnyOf(getProgramAndTourDetails.fulfilled), (state, action) => {
            state.programTourDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(searchSessionData.fulfilled), (state, action) => {
            state.searchSessionData = action.payload;
        });
        builder.addMatcher(isAnyOf(getActivityDetails.fulfilled), (state, action) => {
            state.activityDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(sessionSignupWindow.fulfilled), (state, action) => {
            state.allowSessionSignupWindow = action.payload;
        });
        builder.addMatcher(isAnyOf(getInstructors.fulfilled), (state, action) => {
            state.instructors = action.payload;
        });
        builder.addMatcher(isAnyOf(getSignUpPrecartDetails.fulfilled), (state, action) => {
            state.signUpPrecartDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(validateActivitySignUp.fulfilled), (state, action) => {
            state.processActivitySignUp = action.payload;
        });
        builder.addMatcher(isAnyOf(getProgramSignUpDetails.fulfilled), (state, action) => {
            state.programSignUpDetails = action.payload;
        });
        builder.addMatcher(isAnyOf(getSelectedMeetingTimesForProgram.fulfilled), (state, action) => {
            state.programMeetingData = action.payload;
        });
        builder.addMatcher(isAnyOf(sendTellAFriendMessage.fulfilled), (state, action) => {
            state.sendTellAFriendMessage = action.payload;
        });
        builder.addMatcher(isAnyOf(processActivitySignUpModification.fulfilled), (state, action) => {
            state.processSignUpModification = action.payload;
        });
    })
})
export const { setActivityDetail, allowSessionSignupWindow, setsSignUpPrecartDetails, processActivitySignUp, programSignUpDetails, programMeetingData, setSearchSessionData, processSignUpModification, setSignUpModifyDetails} = activitySlice.actions;