import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { hidePreLoader, showContentLoader, hideContentLoader } from "../../../loader/loaderSlice";
import parksApiRequest from "../../../../app/api/facilities/parksApiRequest";
import { formatDate } from "../../../../app/util/util";
import { store } from "../../../../app/store/configureStore";
import { setPlaceData } from "../search/searchSlice";

interface ParksState {
    nearByPopularPlaces: any[],
    placeFavouriteList: any
}

const initialState: ParksState = {
    nearByPopularPlaces: [],
    placeFavouriteList: []
}

export const getNearByPopularPlaces = createAsyncThunk<any, { searchData: any, selectedPlace: any }>(
    'parks/getNearByPopularPlaces',
    async ({ searchData, selectedPlace }, thunkAPI) => {
        thunkAPI.dispatch(setNearByPopularPlaces([]));
        try {
            let requestData = {
                latitude: selectedPlace ? selectedPlace.Latitude : 0,
                longitude: selectedPlace ? selectedPlace.Longitude : 0,
                startDate: formatDate(searchData.startDate, store.getState().common.applicationSettings.apiDateFormat)
            };
            const nearByPopularPlaces = await parksApiRequest.getNearByPopularPlaces(requestData);
            nearByPopularPlaces.forEach((element: any) => {
                element.Name = element.PlaceName;
                element.CityParkId = element.PlaceId;
            });
            return nearByPopularPlaces;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const markAsFavouritePlace = createAsyncThunk<any, { placeId: number }>(
    'parks/markAsFavouritePlace',
    async ({ placeId }, thunkAPI) => {
        thunkAPI.dispatch(showContentLoader());
        try {
            const response = await parksApiRequest.markAsFavouritePlace(placeId);
            thunkAPI.dispatch(hideContentLoader());
            if (response) {
                let placeFavouriteList = Object.assign([], store.getState().parks.placeFavouriteList);
                placeFavouriteList.push({
                    PlaceId: placeId
                });
                thunkAPI.dispatch(setPlaceFavouriteList(placeFavouriteList));
            }
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const markAsUnFavouritePlace = createAsyncThunk<any, { placeId: number }>(
    'parks/markAsUnFavouritePlace',
    async ({ placeId }, thunkAPI) => {
        thunkAPI.dispatch(showContentLoader());
        try {
            const response = await parksApiRequest.markAsUnFavouritePlace(placeId);
            thunkAPI.dispatch(hideContentLoader());
            if (response) {
                let placeFavouriteList = Object.assign([], store.getState().parks.placeFavouriteList);
                let index = placeFavouriteList.findIndex((ele: any) => { return ele.PlaceId === placeId });
                if (index >= 0) {
                    placeFavouriteList.splice(index, 1);
                }
                thunkAPI.dispatch(setPlaceFavouriteList(placeFavouriteList));
            }
            return;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPlaceFavoriteList = createAsyncThunk<any>(
    'parks/getPlaceFavoriteList',
    async (_, thunkAPI) => {
        try {
            const placeFavouriteList = await parksApiRequest.getPlaceFavoriteList();
            return placeFavouriteList && placeFavouriteList.$values && placeFavouriteList.$values.length > 0 ? placeFavouriteList.$values : [];
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)


export const parksSlice = createSlice({
    name: 'parks',
    initialState,
    reducers: {
        setNearByPopularPlaces: (state, action) => {
            state.nearByPopularPlaces = action.payload;
        },
        setPlaceFavouriteList: (state, action) => {
            state.placeFavouriteList = action.payload;
        },
    },
    extraReducers: (builder => {
        builder.addCase(getNearByPopularPlaces.rejected, (state) => {
            state.nearByPopularPlaces = [];
        });
        builder.addCase(getPlaceFavoriteList.rejected, (state) => {
            state.placeFavouriteList = [];
        });
        builder.addMatcher(isAnyOf(getNearByPopularPlaces.fulfilled), (state, action) => {
            state.nearByPopularPlaces = action.payload;
        });
        builder.addMatcher(isAnyOf(getPlaceFavoriteList.fulfilled), (state, action) => {
            state.placeFavouriteList = action.payload;
        });
    })
})

export const { setNearByPopularPlaces, setPlaceFavouriteList } = parksSlice.actions;