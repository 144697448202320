import format from "date-fns/format";
import { store } from "../store/configureStore";
import { setAlertMessage, setIsSSOAlertAfterVerified } from "../../modules/common/settingsSlice";
import axios from "axios";
import { onOpenModal } from "./ada";

export const EnableCSS = (key: string) => {
    let localStyleSheet = ((document.styleSheets as unknown) as any[]);
    for(let i=0; i < localStyleSheet.length; i++)
    {
      if(!localStyleSheet[i].href)
      {
        for(let z=0; z < localStyleSheet[i].cssRules.length; z++)
        {
          if(localStyleSheet[i].cssRules[z].cssText.includes(key))
          {
            localStyleSheet[i].disabled = false;
          }
        }
      }
    }
}

export const DisableCSS = (key: string) => {
    let localStyleSheet = ((document.styleSheets as unknown) as any[]);
    for(let i=0; i < localStyleSheet.length; i++)
    {
      if(!localStyleSheet[i].href)
      {
        for(let z=0; z < localStyleSheet[i].cssRules.length; z++)
        {
          if(localStyleSheet[i].cssRules[z].cssText.includes(key))
          {
            localStyleSheet[i].disabled = true;
          }
        }
      }
    }
}

export const addScript = (url: string) => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);
}

export const addCSS = (url: string) => {
    var head = document.getElementsByTagName('head')[0];
    
    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = url;
    link.media = 'all';
    
    head.appendChild(link);
}

export const getTemplateData = (file: string, enterpriseName: string, callback: any) => {
    axios.get(store.getState().common.applicationSettings.imageBaseUrl + 'themes/' + enterpriseName + '/' + file).then((response) => {
        if (response.data && response.data.includes("baseIdForEnterprise")) {
            callback("")
        } else {
            callback(response.data)
        }
    }).catch((error) => {
        callback("")
    })
}

export const formatDate = (date: any, dateFormat: string) => {
    date = new Date(date);
    return format(date, dateFormat);
}

export const formatDateWithTimeZone = (date: any, dateFormat: string) => {
    date = new Date(date);
    return format(date, dateFormat) + " 00:00:00";
}

export const getDateWithTimeZone = (date: any) => {
    return new Date(date + " 00:00:00");
}

export const formatDateToDisplay = (date: any) => {
    date = new Date(date);
    return format(date, store.getState().common.applicationSettings.dateFormat);
}

export const getDayDifference = (date1: any, date2: any) => {
    date1 = new Date(date1);
    date2 = new Date(date2);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Math.ceil(Difference_In_Days);
}

export const capitalizeFirstLetter = (string: any) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const camalize = (text: any) => {
    return text.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
}

export const getDefaultSearchData = (callback: any) => {
    let bookingDetail: any = localStorage.getItem("booknow") ? JSON.parse(localStorage.getItem("booknow") || '{}') : null;
    let minDate = store.getState().common.futureBookingsDate.FutureBookingStartDate ? store.getState().common.futureBookingsDate.FutureBookingStartDate : new Date();
    let startDate = new Date(minDate);
    let endDate = new Date(new Date(minDate).getTime() + (1000 * 60 * 60 * 24));
    let nights: any = 1;
    let parkId = store.getState().common.webSiteSettings?.facility_default_place_id;
    if (bookingDetail) {
        startDate = bookingDetail.SearchStartDate ? new Date(bookingDetail.SearchStartDate) : startDate;
        endDate = bookingDetail.SearchEndDate ? new Date(bookingDetail.SearchEndDate) : endDate;
        nights = bookingDetail.Nights ? bookingDetail.Nights : nights;
        parkId = bookingDetail.PlaceId ? bookingDetail.PlaceId : parkId;
        localStorage.removeItem("booknow");
    } else {
        if (sessionStorage.getItem("selectedStartdate") && sessionStorage.getItem("selectedEnddate")) {
            startDate = new Date(sessionStorage.getItem("selectedStartdate") || '');
            endDate = new Date(sessionStorage.getItem("selectedEnddate") || '');
        }
        //parkId = sessionStorage.getItem("selectedPlaceId") ? sessionStorage.getItem("selectedPlaceId") : parkId;
        nights = sessionStorage.getItem("selectedNights") ? sessionStorage.getItem("selectedNights") : nights;
    }
    callback(startDate, endDate, nights, parkId);
}

export const getFormattedTime = (date: any) => {
    let currentDate = new Date(date);
    let hour = currentDate.getHours();
    let minute = currentDate.getMinutes();
    let suffix = (hour >= 12) ? "pm" : "am";
    return ("0" + (hour > 12 ? (hour - 12) : hour)).slice(-2) + ":" + ("0" + minute).slice(-2) + " " + suffix;
}

export const convertAMPMToTimeStamp = (time: any) => {
    let hours = Number(time.match(/^(\d+)/)[1]);
    let minutes = Number(time.match(/:(\d+)/)[1]);
    let AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === "pm" && hours < 12) hours = hours + 12;
    if (AMPM === "am" && hours === 12) hours = hours - 12;
    let sHours: any = hours.toString();
    let sMinutes: any = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return new Date(2021, 1, 1, sHours, sMinutes, 0, 0).getTime();
}

export const convertAMPMToTime = (time: any) => {
    if (time == null || time == undefined)
        return;
    let hours = Number(time.match(/^(\d+)/)[1]);
    let minutes = Number(time.match(/:(\d+)/)[1]);
    let AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM == "pm" && hours < 12) hours = hours + 12;
    if (AMPM == "am" && hours == 12) hours = hours - 12;
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) sHours = "0" + sHours;
    if (minutes < 10) sMinutes = "0" + sMinutes;
    return sHours + ":" + sMinutes;
}

export const setDocumentTitle = (title: any) => {
    document.title = title;
}

export const skipContent = () => {
    let element: any = document.getElementsByClassName("skip-to-content-focus");
    element[0].focus();
}

export const setFocusToElement = (ref: any, isClass: any) => {
    if (isClass) {
        let element: any = document.getElementsByClassName(ref);
        if (element && element.length > 0) {
            element[0].focus();
        }
    } else {
        let element = document.getElementById(ref);
        if (element) {
            element.focus();
        }
    }
}

export const getAPIUrl = () => {
    return process.env.REACT_APP_RDR_API_URL;
}

export const validateEmail = (email: any) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

export const onFacilityLoad = () => {
    let availableLead = document.getElementById("available-facility-lead");
    if (availableLead) {
        availableLead.focus();
    } else {
        setTimeout(() => {
            onFacilityLoad();
        }, 500);
    }
}

export const changeIdOfList = () => {
    setTimeout(() => {
        let elements: any = document.getElementsByClassName("select-options-container");
        if (elements && elements.length > 0) {
            let index = 0;
            for (let ele of elements) {
                ele.setAttribute('id', "listbox--" + index);
                index++;
            }
        }
    }, 500);
}

const shiftTabOnMap = (event: any) => {
    if (event.shiftKey && event.keyCode === 9) {
        if (event.preventDefault) {
            event.preventDefault();
        }
        var element = document.getElementById("back-to-park");
        if (element) {
            element.focus();
        }
        return false;
    } else {
        return true;
    }

}

const availableSiteTabKeyHandler = (e: any) => {
    var TABKEY = 9;
    if (e.keyCode === TABKEY) {
        let favElement = document.getElementsByClassName("unit-grid-fav");
        if (favElement && favElement.length > 0) {
            return true;
        } else {
            if (e.preventDefault) {
                e.preventDefault();
            }
            var element = document.getElementById("grid-search-map");
            if (element) {
                element.focus();
                if (element.addEventListener) {
                    element.addEventListener('keydown', shiftTabOnMap, false);
                }
                setGridTabIndex(0, function (index: any) { });
            }
            return false;
        }
    }
}

export const setGridTabIndex = (tabIndex: any, callback?: any) => {
    let availableSite: any = document.getElementsByClassName("available-unit");
    let index = 0;
    if (availableSite && availableSite.length > 0) {
        let indexArray = [];
        for (let element of availableSite) {
            let firstAvailableClassList = element.classList;
            indexArray.push(parseInt(firstAvailableClassList[firstAvailableClassList.length - 1]));
        }
        if (indexArray.length > 0) {
            indexArray.sort();
            index = indexArray[0];
            if (tabIndex === 0) {
                for (let element of availableSite) {
                    let classList = element.classList;
                    if (parseInt(classList[classList.length - 1]) === index) {
                        element.setAttribute("tabindex", 0);
                    } else {
                        element.setAttribute("tabindex", -1);
                    }
                }
                if (callback) {
                    callback(null);
                }
            } else {
                let elementArray = [];
                for (let element of availableSite) {
                    let classList = element.classList;
                    if (parseInt(classList[classList.length - 1]) === index) {
                        element.setAttribute("tabindex", 0);
                        elementArray.push(element);
                    } else {
                        element.setAttribute("tabindex", -1);
                    }
                }

                let lastElement = elementArray[elementArray.length - 1];
                if (lastElement.addEventListener) {
                    lastElement.addEventListener('keydown', availableSiteTabKeyHandler, false);
                } else if (lastElement.attachEvent) {
                    lastElement.attachEvent('onkeydown', availableSiteTabKeyHandler);
                }
                if (callback) {
                    callback(elementArray[0]);
                }
            }
        } else {
            if (callback) {
                callback(null);
            }
        }
    } else {
        if (callback) {
            callback(null);
        }
    }
}

const noSiteAvailableTabKeyHandler = (e: any) => {
    var TABKEY = 9;
    if (e.keyCode === TABKEY) {
        if (e.preventDefault) {
            e.preventDefault();
        }
        var element = document.getElementById("back-to-park");
        if (element) {
            element.focus();
        }
        return false;
    }
}

export const onGridLoad = (isOpenCamping: boolean) => {
    let availableSite = document.getElementsByClassName("available-unit") as HTMLCollectionOf<HTMLElement>;
    let notAvailableSite = document.getElementsByClassName("not-available-unit");
    let noSites = document.getElementById("no-sites");
    if (noSites) {
        noSites.style.display = "none";
    }
    if (availableSite.length > 0 || notAvailableSite.length > 0) {
        if (isOpenCamping) {
            if (availableSite.length > 0) {
                availableSite[0].focus();
            }
        } else {
            if (availableSite.length > 0) {
                setGridTabIndex(null, function (element: HTMLElement) {
                    let favElement = document.getElementsByClassName("unit-grid-fav") as HTMLCollectionOf<HTMLElement>;
                    if (favElement && favElement.length > 0) {
                        favElement[0].focus();
                    } else {
                        element.focus();
                    }
                });
            }
            else if (availableSite.length > 0 && notAvailableSite.length === 0) {
                setGridTabIndex(null, function (element: HTMLElement) {
                    let favElement = document.getElementsByClassName("unit-grid-fav") as HTMLCollectionOf<HTMLElement>;
                    if (favElement && favElement.length > 0) {
                        favElement[0].focus();
                    } else {
                        element.focus();
                    }
                });
            } else {
                let noSites = document.getElementById("no-sites") as HTMLElement;
                if (noSites) {
                    noSites.style.display = "block";
                    setTimeout(() => {
                        noSites.focus();
                        if (noSites.addEventListener) {
                            noSites.addEventListener('keydown', noSiteAvailableTabKeyHandler, false);
                        }
                    }, 1000);
                }
            }
        }
    } else {
        setTimeout(() => {
            onGridLoad(isOpenCamping);
        }, 500);
    }
}

export const openLoginModal = () => {
    const enableSsoLogin = store.getState().common.webSiteSettings?.enable_sso_login;
    if (enableSsoLogin) {
        store.dispatch(setIsSSOAlertAfterVerified(false));
        onOpenModal("ssoLoginAlert", "ssoLoginAlertHeader", "ssoLoginAlertInfo", "ssoLoginAlertHeader");
    }
    else {
        onOpenModal("loginModal", "txtEmailAddress", "loginCreateAccount", "loginCloseBtn");
    }
}

export const checkLogin = () => {
    const isLogin = store.getState().authentication.isLoggedIn;
    if (isLogin) {
        return true;
    }
    else {
        openLoginModal();
        return false;
    }
}

export const timediff = (startDate: any, currentDateTime: any) => {
    let start = new Date(startDate);
    let end = new Date(currentDateTime);
    let difference_In_Time = Math.abs(start.getTime() - end.getTime()) / 1000;
    if (difference_In_Time < 60) {
        return Math.round(difference_In_Time).toString() + " seconds ago";
    }
    else if ((difference_In_Time / 60) < 60) {
        return Math.round(difference_In_Time / 60).toString() + " minute(s) ago";
    }

    else if ((difference_In_Time / 3600) < 24) {
        return Math.round(difference_In_Time / 3600).toString() + " hour(s) ago";
    }

    else {
        return Math.round(difference_In_Time / 86400).toString() + " day(s) ago";
    }
}

export const encryptCustomerId = (customerId: any) => {
    return btoa(customerId.toString())
}

export const decryptCustomerId = (customerId: any) => {
    return atob(customerId.toString())
}

export const getFormattedPrice = (price: any) => {
    return "$" + (Math.round(price * 100) / 100).toFixed(2).toString()
}

export const encryptInstallationIdentity = (installationIdentity: any) => {
    return btoa(installationIdentity.toString())
}

export const decryptInstallationIdentity = (installationIdentity: any) => {
    return atob(installationIdentity.toString())
}

export const onlyNumber = (evt: any) => {
    if (!/[0-9]/.test(evt.key)) {
        evt.preventDefault();
    }
}

export const removeSpecialChar = (event: any) => {
    let $ = (window as any).$;
    $('#' + event.currentTarget.id).removeAttr('maxlength');
    var _this = $('#' + event.currentTarget.id);
    setTimeout(function () {
        var numsStr = $(_this).val().replace(/[^0-9]/g, '');
        numsStr = numsStr.substring(0, 10);
        $(_this).attr('maxlength', '10');
        $(_this).val(numsStr);
    }, 100);
}

export const phoneNumberValidation = (evt: any, isMessageShow: boolean, message: string) => {
    if (evt.which > 31 && (evt.which < 48 || evt.which > 57)) {
        if (isMessageShow) {
            store.dispatch(setAlertMessage({ className: "loginFailpopup", header: "Message", message: message }))
        }
        evt.preventDefault();
    }
}

export const onlyNumberWithFloat = (evt: any) => {
    if (/[^0-9.]/.test(evt.key)) {
        evt.preventDefault();
    }
    if (evt.charCode === 46 && evt.target.value.split('.').length === 2) {
        evt.preventDefault();
    }
}

export const registerScripts = (scriptUrl: string) => {
    const script = document.createElement("script");
    script.src = scriptUrl;
    document.body.appendChild(script);
}

export const replaceBRTag = (stringVal: string) => {
    return stringVal.replace(/\r\n|\n|\r/g, "<br/>");
}

export const addOneYear = (date: any) => {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() + 1);
    return dateCopy;
}

export const validateTime = (time: any) => {
    return String(time)
        .toLowerCase()
        .match(/^\b((1[0-2]|0?[1-9]) ([AaPp][Mm])$)/);
};