import { rdrRequests } from "../httpRequest";

const searchApiRequest = {
    getPopularPlaces: (limit: number) => rdrRequests.get(`search/popular/places/${limit}`),
    getPlaceSuggestion: (searchText: string) => rdrRequests.get(`fd/citypark/namecontains/${searchText}`),
    getCityPark: (placeId: number) => rdrRequests.get(`fd/citypark/${placeId}`),
    getPlaceFilters: (placeId: number) => rdrRequests.get(`search/filters/${placeId}`),
    searchPlace: (values: any) => rdrRequests.post(`search/place`, values)
}

export default searchApiRequest;