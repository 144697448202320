import { authorizedRDRequests,rdRequests } from "../httpRequest";

const shoppingCartApiRequest = {
    getShoppingCartList: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/load/shoppingcart`, requestData),
    emptyCart: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/empty/shoppingcart`, requestData),
    removeCartEntry: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/remove/cartentry`, requestData),
    getOtherShoppingCartDetail: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/load/othersshoppingcart`, requestData),
    addDonationShoppingCart: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/add/donationshoppingcart`, requestData),
    applyPromoCode: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/apply/promocodeshoppingcart`, requestData),
    extendShoppingCartTimer: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/extend/shoppingcarttimer`, requestData),
    cancelCartEntry: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/cancel/reservation`, requestData),
    cancelHCSignUp: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/cancel/GetHCSignUpCancellationCart`, requestData),
    cancelSignUp: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/cancel/GetSignUpCancellationCart`, requestData),
    cancelCustomerMembershipPass: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/cancel/GetCustomerPasses`, requestData),
    checkoutShoppingCart: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/Checkout/ShoppingCart`, requestData),
    checkoutPaymentResponse :(requestData: any) => authorizedRDRequests.post(`webaccesscustomer/payment/CheckoutPaymentResponse`, requestData),
    modifyReservation: (requestData: any) => authorizedRDRequests.post(`webaccessfacility/Load/PrecartDataforBookingModify`, requestData),
    initiatePayment:(requestData: any) => authorizedRDRequests.post(`rdaccesstylerpayments/InitiatePayment`, requestData),    
    getTylerPaymentResponse: (tylerPaymentId: any) => rdRequests.get(`rdaccesstylerpayments/GetTylerPaymentResponse?tylerPaymentId=${tylerPaymentId}`),
    modifySignUp: (requestData: any) => authorizedRDRequests.post(`WebAccessActivity/modify/SignUp`, requestData),
    roundUpDonationShoppingCart: (requestData: any) => authorizedRDRequests.post(`webaccesscustomer/add/RoundUpDonationShoppingCart`, requestData),
}

export default shoppingCartApiRequest;