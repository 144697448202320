import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import webCheckInApiRequest from "../../app/api/webcheckin/webCheckInApiRequest";
import { store } from "../../app/store/configureStore"
import { setAlertMessage } from "../../../src/modules/common/settingsSlice";
import { hidePreLoader, showContentLoader, hideContentLoader } from "../../modules/loader/loaderSlice";

interface WebCheckInState {
    webCheckInDetails: any
}
enum FacilityReservationStatusCodeTypes {
    SC_ERR02_CHECKIN = 'SC_ERR02_CHECKIN',
    SC_VALID_CHECKEDIN = 'SC_VALID_CHECKEDIN',
    SC_ERR01_INVALID_CONF = 'SC_ERR01_INVALID_CONF',
    SC_ERR03_INVALID_CUSOMER = 'SC_ERR03_INVALID_CUSOMER',
    SC_ERR05_CHECKOUT = 'SC_ERR05_CHECKOUT',
    SC_ERR06_NOSHOW = 'SC_ERR06_NOSHOW',
    SC_ERR04_UNKNOWN_ERROR = 'SC_ERR04_UNKNOWN_ERROR'
}
const initialState: WebCheckInState = {
    webCheckInDetails: []
}

export const validateCheckInReservation = createAsyncThunk<any, { requestData: any }>(
    'webcheckin/validatecheckinreservation',
    async ({ requestData }, thunkAPI) => {
        thunkAPI.dispatch(showContentLoader());
        try {
            let request: any = {
                "confirmationNumber": requestData.confirmationNumber,
                "lastName": requestData.lastName,
                "placeId": requestData.placeId
            };
            const getCheckInDetail = await webCheckInApiRequest.validateCheckInReservation(request);
            thunkAPI.dispatch(hideContentLoader());
            let dynamicConfigMsg = store.getState().common.dynamicConfigMsg;
            if (getCheckInDetail != null && getCheckInDetail.$values.length > 0) {
                var lstSuccessCheckIn = getCheckInDetail.$values.filter((s: any) => s.Message == FacilityReservationStatusCodeTypes.SC_VALID_CHECKEDIN);
                var lstAlreadyCheckIn = getCheckInDetail.$values.filter((s: any) => s.Message == FacilityReservationStatusCodeTypes.SC_ERR02_CHECKIN);
                var confirmationNotvalid = getCheckInDetail.$values.filter((s: any) => s.Message == FacilityReservationStatusCodeTypes.SC_ERR01_INVALID_CONF);
                var invalidCustomer = getCheckInDetail.$values.filter((s: any) => s.Message == FacilityReservationStatusCodeTypes.SC_ERR03_INVALID_CUSOMER);
                var alreadyCheckedOut = getCheckInDetail.$values.filter((s: any) => s.Message == FacilityReservationStatusCodeTypes.SC_ERR05_CHECKOUT);
                var checkInNotShow = getCheckInDetail.$values.filter((s: any) => s.Message == FacilityReservationStatusCodeTypes.SC_ERR06_NOSHOW);
                var checkInUnknownError = getCheckInDetail.$values.filter((s: any) => s.Message == FacilityReservationStatusCodeTypes.SC_ERR04_UNKNOWN_ERROR);

                if (lstSuccessCheckIn.length > 0) {
                    let webCheckInDetails = store.getState().webCheckIn.webCheckInDetails;
                    webCheckInDetails = getCheckInDetail.$values;
                    thunkAPI.dispatch(setWebCheckInDetails(webCheckInDetails));
                    thunkAPI.dispatch(setAlertMessage({ header: "Information", message: dynamicConfigMsg['Web_CheckIn_Successfull'] }));
                }
                else if (lstAlreadyCheckIn.length > 0) {
                    let webCheckInDetails = store.getState().webCheckIn.webCheckInDetails;
                    webCheckInDetails = getCheckInDetail.$values;
                    thunkAPI.dispatch(setWebCheckInDetails(webCheckInDetails));
                    thunkAPI.dispatch(setAlertMessage({ header: "Information", message: dynamicConfigMsg['Web_Already_CheckedIn'] }));
                }
                else if (confirmationNotvalid.length > 0) {
                    thunkAPI.dispatch(setAlertMessage({ header: "Information", message: dynamicConfigMsg['Web_CheckIn_Confirmation_Notvalid'] }));
                }
                else if (invalidCustomer.length > 0) {
                    thunkAPI.dispatch(setAlertMessage({ header: "Information", message: dynamicConfigMsg['Web_CheckIn_Invalid_Customer'] }));
                }
                else if (alreadyCheckedOut.length > 0) {
                    thunkAPI.dispatch(setAlertMessage({ header: "Information", message: dynamicConfigMsg['Web_Already_Checked_Out'] }));
                }
                else if (checkInNotShow.length > 0) {
                    thunkAPI.dispatch(setAlertMessage({ header: "Information", message: dynamicConfigMsg['Web_CheckIn_NotShow'] }));
                }
                else if (checkInUnknownError.length > 0) {
                    thunkAPI.dispatch(setAlertMessage({ header: "Information", message: dynamicConfigMsg['Web_CheckIn_Unknown_Error'] }));
                }
            }
            else {
                thunkAPI.dispatch(setAlertMessage({ header: "Information", message: dynamicConfigMsg['Web_CheckIn_Unknown_Error'] }));
            }
        }
        catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)
export const webCheckInSlice = createSlice({
    name: 'webcheckin',
    initialState,
    reducers: {
        setWebCheckInDetails: (state, action) => {
            state.webCheckInDetails = action.payload
        }
    },
    extraReducers: (builder => {
        builder.addCase(validateCheckInReservation.rejected, (state) => {
            state.webCheckInDetails = [];
        });
    })
})
export const { setWebCheckInDetails } = webCheckInSlice.actions;