export default function setColorsFromThemeJsonTS(enterprise: string) {
    let cssId = enterprise + "_css";
    if (!document.getElementById(cssId)) {
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.id = cssId;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `themes/${enterprise}/css/color.css`;
        link.media = 'all';
        head.appendChild(link);
        document.getElementsByTagName('body')[0].classList.add(enterprise);
    }
    let favIcon = document.getElementById("favIcon");
    if(favIcon){
        favIcon.setAttribute("href",`themes/${enterprise}/favicon.ico`);
    }
}