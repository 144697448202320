import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { hidePreLoader, showPreLoader, showContentLoader, hideContentLoader } from "../../../loader/loaderSlice";
import drawApiRequest from "../../../../app/api/facilities/drawApiRequest";

interface DrawState {
    drawApplicationData: any,
    placeList: any,
    customerDrawApplicationData: any
}

const initialState: DrawState = {
    drawApplicationData: null,
    placeList: [],
    customerDrawApplicationData: null
}

export const getDrawApplicationData = createAsyncThunk<any, { placeId: number, facilityId: number, startDate: string }>(
    'draw/getDrawApplicationData',
    async ({ placeId, facilityId, startDate }, thunkAPI) => {
        thunkAPI.dispatch(showPreLoader());
        try {
            let drawApplicationData: any = await drawApiRequest.getDrawApplicationData(placeId, facilityId, startDate);
            let unitTypeOptions = [];
            if (drawApplicationData && drawApplicationData.UnitTypes) {
                for (var key in drawApplicationData.UnitTypes) {
                    unitTypeOptions.push({
                        value: parseInt(key),
                        label: drawApplicationData.UnitTypes[key]
                    });
                }
            }
            drawApplicationData.UnitTypeOptions = unitTypeOptions;
            thunkAPI.dispatch(hidePreLoader());
            return drawApplicationData;
        } catch (error: any) {
            thunkAPI.dispatch(hidePreLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getPlaceList = createAsyncThunk<any>(
    'draw/getPlaceList',
    async (_, thunkAPI) => {
        try {
            let placeList: any = await drawApiRequest.getPlaceList();
            placeList = placeList.filter((ele: any) => { return ele.AllowWebBooking && ele.IsWebViewable; });
            placeList = placeList.map((item: any) => {
                return {
                    value: item.PlaceId,
                    label: item.Name
                };
            });
            return placeList;
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getCustomerDrawApplicationData = createAsyncThunk<any, { requestData: any }>(
    'draw/getCustomerDrawApplicationData',
    async ({ requestData }, thunkAPI) => {
        thunkAPI.dispatch(showContentLoader());
        thunkAPI.dispatch(setCustomerDrawApplicationData(null));
        try {
            let customerDrawApplicationData: any = await drawApiRequest.getCustomerDrawApplicationData(requestData);
            thunkAPI.dispatch(hideContentLoader());
            return customerDrawApplicationData;
        } catch (error: any) {
            thunkAPI.dispatch(hideContentLoader());
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)


export const drawSlice = createSlice({
    name: 'draw',
    initialState,
    reducers: {
        setCustomerDrawApplicationData: (state, action) => {
            state.customerDrawApplicationData = action.payload;
        }
    },
    extraReducers: (builder => {
        builder.addCase(getDrawApplicationData.rejected, (state) => {
            state.drawApplicationData = null;
        });
        builder.addCase(getPlaceList.rejected, (state) => {
            state.placeList = [];
        });
        builder.addCase(getCustomerDrawApplicationData.rejected, (state) => {
            state.customerDrawApplicationData = null;
        });
        builder.addMatcher(isAnyOf(getDrawApplicationData.fulfilled), (state, action) => {
            state.drawApplicationData = action.payload;
        });
        builder.addMatcher(isAnyOf(getPlaceList.fulfilled), (state, action) => {
            state.placeList = action.payload;
        });
        builder.addMatcher(isAnyOf(getCustomerDrawApplicationData.fulfilled), (state, action) => {
            state.customerDrawApplicationData = action.payload;
        });
    })
})

export const { setCustomerDrawApplicationData } = drawSlice.actions;